.ant-picker-panel-container {
  border-radius: 8px;
}

.ant-picker.ant-picker-multiple .ant-picker-selection-item {
  margin-block: 2px;
}

.ant-picker.ant-picker-multiple {
  padding-top: 8px;
  padding-bottom: 6px;
  padding-inline-start: 6px;
}
